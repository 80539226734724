<template>
<div class="apCodeUpload-page-content winner-page__hero">
   <div class="main-block position-relative">
      <div class="container-xl py-lg-5">
        <div class="row" :class="winnerType == 'heti' ? 'winner-bg--heti':''">
            <div class="col-12 pt-5 mt-lg-5">
                <!-- mobile -->
                <!-- <div class="d-md-none winner-page__winning">
                    <img v-if="winnerType == 'napi'" src="@/assets/imgs/winnings-napi.png" alt="napi nyememény" class="img-fluid" width="350"/>
                    <img v-if="winnerType == 'heti'" src="@/assets/imgs/winnings-heti.png" width="90%" alt="heti nyeremény" class="img-fluid"/>
                    <img v-if="winnerType == 'fodij'" src="@/assets/imgs/winnings-fodij.png" class="img-fluid ">
                </div> -->

                <div class="py-lg-5 my-lg-5">
                    <div class="row py-md-5 pt-0 my-5 mx-md-2 mx-0">
                        <div class="col-12 col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 col-md-10 d-md-flex justify-content-start flex-row align-items-center winner-bg">
                            <div class="text-left py-3 pe-5 ps-5">
                                <h1 class="text-secondary text-uppercase fw-900 mb-3" style="border-radius:5px;">Gratulálunk!</h1>  
                                <p class="fs-22 text-secondary winner-text">Érvényes pályázat esetén nyereménye lehet </p>            
                                <p class="text-primary fs-32">
                               <!--     <span class="winner-page__hero__winning" v-if="winnerType == 'napi'"><span class="">1 db 20 000 Ft értékű Purina eledel csomag</span></span>
                                   --> <span class="winner-page__hero__winning " v-if="winnerType == 'heti'"><span class="">1 db családi belépő a Fővárosi Állat- és Növénykertbe</span></span>
                                    <span class="winner-page__hero__winning " v-if="winnerType == 'fodij'"><span class="">1 db buszos osztálykirándulás a Fővárosi Állat- és Növénykertbe</span></span>
                                </p>
                            </div>
                            <img v-if="winnerType == 'heti'" src="@/assets/imgs/winnings-heti.png" alt="heti nyeremény" class="img-fluid winner-img d-none d-md-block"/>
                            <img v-if="winnerType == 'fodij'" src="@/assets/imgs/winnings-fodij.png" class="img-fluid winner-img me-3 d-none d-md-block">
                        </div>      
                        <!-- desctiop -->
                         <!--<div class="col-12 col-md-4 position-relative winner-page__winning">
                           <img v-if="winnerType == 'napi'" src="@/assets/imgs/winnings-napi.png" alt="napi nyememény" class="img-fluid mb-2"/>
                                                       
                        </div>--> 
                        <img v-if="winnerType == 'heti'" src="@/assets/imgs/winnings-heti.png" alt="heti nyeremény" class="img-fluid d-block d-md-none"/>
                        <img v-if="winnerType == 'fodij'" src="@/assets/imgs/winnings-fodij.png" class="img-fluid me-3 d-block d-md-none">
                    </div>
                </div>
            </div>
        </div>   
    </div>    
  </div>
  <!--<Ribbon/>-->
</div>
</template>

<script>
//import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        //Ribbon
    },
    props:['winnerType']
}
</script>